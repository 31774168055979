'use client';

import { Button } from '@nextui-org/button';
import { cn } from '@nextui-org/theme';
import * as React from 'react';
import { useRouter, useSearchParams } from 'next/navigation';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { FallbackImage, RHFAutocomplete } from '@/components';
import { heading } from '@/theme/typography';
import { useModels, useSeries } from '@/hooks/queries';

interface ReviewsFilterProps {
  className?: string;
  classNames?: {
    mainWrapper: string;
  };
}

interface FormAttributes {
  series: string | null;
  model: string | null;
}

const ReviewsFilter: React.FC<ReviewsFilterProps> = ({ className, classNames }) => {
  const router = useRouter();
  const searchParams = useSearchParams();

  const { series, isLoading: isSeriesLoading } = useSeries({});
  const { models, isLoading: isModelsLoading } = useModels({});

  const method = useForm<FormAttributes>({
    defaultValues: {
      series: searchParams.get('series') ?? 'All',
      model: searchParams.get('model'),
    },
  });
  const { handleSubmit } = method;

  const handleSearch: SubmitHandler<FormAttributes> = ({ series, model }) => {
    const searchParams = new URLSearchParams();

    if (series && series !== 'all') searchParams.set('series', series);
    if (model && model !== 'all') searchParams.set('model', model);

    router.replace(`/reviews/search?${searchParams.toString()}`);
  };

  return (
    <div
      className={cn(
        'relative flex flex-row items-center overflow-hidden bg-blue-950 text-neutral-white lg:h-[480px]',
        className,
      )}
    >
      <div className={cn('container py-10', classNames?.mainWrapper)} data-slot='main-wrapper'>
        <div className='relative z-30 flex w-full flex-col gap-4 lg:w-7/12 xl:w-5/12'>
          <h2 className={heading({ size: 'h2' })}>Search for car review</h2>

          <FormProvider {...method}>
            <form className='flex w-full flex-col items-stretch gap-6'>
              <div className='flex w-full flex-col items-stretch gap-4'>
                <RHFAutocomplete
                  aria-label='Series'
                  className='w-full lg:min-w-36'
                  inputProps={{
                    classNames: {
                      mainWrapper: 'rounded-none',
                      inputWrapper: cn(
                        'shadow-none bg-neutral-white',
                        'data-[hover=true]:!bg-neutral-white group-data-[hover=true]:!bg-neutral-white',
                        'data-[focus=true]:!bg-neutral-white group-data-[focus=true]:!bg-neutral-white',
                      ),
                      input: 'text-neutral-black',
                      label: '!text-neutral-white',
                    },
                  }}
                  isClearable={false}
                  isLoading={isSeriesLoading}
                  items={[
                    { label: 'All series', value: 'all' },
                    ...(series?.series.map(({ id, name }) => ({ label: name, value: id })) ?? []),
                  ]}
                  label='Series'
                  labelPlacement='outside'
                  name='series'
                  placeholder='Choose a series'
                  size='lg'
                  variant='flat'
                />

                <RHFAutocomplete
                  aria-label='Series'
                  className='w-full lg:min-w-36'
                  inputProps={{
                    classNames: {
                      mainWrapper: 'rounded-none',
                      inputWrapper: cn(
                        'shadow-none bg-neutral-white',
                        'data-[hover=true]:!bg-neutral-white group-data-[hover=true]:!bg-neutral-white',
                        'data-[focus=true]:!bg-neutral-white group-data-[focus=true]:!bg-neutral-white',
                      ),
                      input: 'text-neutral-black',
                      label: '!text-neutral-white',
                    },
                  }}
                  isClearable={false}
                  isLoading={isModelsLoading}
                  items={[
                    { label: 'All models', value: 'all' },
                    ...(models?.map((model) => ({
                      label: model.name,
                      value: model.id.toString(),
                    })) ?? []),
                  ]}
                  label='Model'
                  labelPlacement='outside'
                  name='model'
                  placeholder='Choose a model'
                  size='lg'
                  variant='flat'
                />
              </div>

              <Button
                className='bg-blue-500'
                color='primary'
                radius='none'
                size='lg'
                onClick={handleSubmit(handleSearch)}
              >
                Search
              </Button>
            </form>
          </FormProvider>
        </div>
      </div>

      <FallbackImage
        alt='BMW Reviews'
        className='absolute left-0 top-1/2 z-20 hidden w-full -translate-y-1/2 select-none lg:block'
        height={360}
        loading='lazy'
        src='https://storage.googleapis.com/upload-xdata/xdata-file/undefined/data-crawled/fb3eb7df-f078-4c83-9647-336e15af70cc/bmw-reviews.png'
        width={1200}
      />

      <div
        className={cn('absolute bottom-0 z-10 h-full w-full', 'bg-gradient-to-b from-blue-950')}
      />
      <div
        className={cn(
          'absolute bottom-0 z-0 h-full w-full',
          'bg-gradient-to-r from-bmw-red-500 via-bmw-blue-500 to-bmw-blue-300 mix-blend-hard-light',
        )}
      />
      <div className='absolute left-0 top-0 h-[300px] w-full bg-gradient-to-b from-blue-950 from-40%' />
    </div>
  );
};

export default ReviewsFilter;
